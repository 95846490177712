.top-header {
    background: $brand-success;
    width: 100%;
}

.logo-container {
    width: 420px;
    line-height:35px;

	@include phone {
		width: 220px;
	}
}

.logo-adds {
    color: #fff;
    font-weight: 100;
    opacity: .8;
    position: relative;
    font-size: 24px;
    font-family: 'Noto Sans', sans-serif;
    display:inline-block;
    vertical-align: middle;
    padding-left: 5px;
}

@media screen and (max-width: 768px) {
    .logo-adds {
        display: none;
    }
}


.btn-link {
    color: white;
    cursor:pointer;
    padding-left: 0;
    padding-right: 0;
    font-weight: 200;

    &:hover {
        color: white;
    }
}

#homepage {
    background-color: lighten($brand-success, 0.7) !important;

    h1,h2,h3 {
        color: white;
    }
}
