.integration-item {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__header {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #E7E7E7;
		padding: 13px 0;
	}

	&__body {
		width: 100%;
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid #dadada;

		&:last-of-type {
			border-bottom: 0;
		}

		&.active {
			.general {
				background-color: rgba(61, 131, 223, 0.07);
				border: 1px solid rgba(47, 128, 237, 0.3);
				border-radius: 4px;
			}

			.integration-item__details {
				display: block;
			}
		}
	}

	.general {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 13px 0;
		border: 1px solid transparent;

		&:hover {
			background-color: rgba(61, 131, 223, 0.07);
			border: 1px solid rgba(47, 128, 237, 0.3);
			border-radius: 4px;
		}

		.name {
			font-weight: bold;
		}
	}

	.status {
		display: flex;
		justify-content: center;
		min-width: 100px;

		> i {
			font-size: 11px;
			filter: blur(2px);
		}

		&.up {
			color: #1FBC41;
		}

		&.down {
			color: #FF0E0E;
		}
		&.disabled {
			color: #333333;
		}
	}

	.name {
		display: flex;
		justify-content: flex-start;
		width: 100%;
	}

	.response-time {
		display: flex;
		justify-content: center;
		min-width: 220px;
	}

	&__details {
		display: none;
	}
}
