body {
  background: $gray-lightest !important;
  text-rendering: optimizeLegibility;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.5715;
  color: #333;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
}

.hide {
  display: none;
}

a {
  color: $links;
}

.full-width {
  width: 100%;
}

.fa + span.icon-text
{
  margin-left:5px;
}
.menu-item-divider
{
  color:#fff;
}

.select2-dropdown {
  border: 1px solid #e0e4e4;
  border-radius: 3px;
}

.styled-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Positions background arrow image */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0nMS4wJyBlbmNvZGluZz0ndXRmLTgnPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEyOSAxMjkiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im0xMjEuMywzNC42Yy0xLjYtMS42LTQuMi0xLjYtNS44LDBsLTUxLDUxLjEtNTEuMS01MS4xYy0xLjYtMS42LTQuMi0xLjYtNS44LDAtMS42LDEuNi0xLjYsNC4yIDAsNS44bDUzLjksNTMuOWMwLjgsMC44IDEuOCwxLjIgMi45LDEuMiAxLDAgMi4xLTAuNCAyLjktMS4ybDUzLjktNTMuOWMxLjctMS42IDEuNy00LjIgMC4xLTUuOHoiLz4KICA8L2c+Cjwvc3ZnPgo=) no-repeat right center;
  background-size: 40px 20px;
}

.close {
  cursor:pointer;
  color: #000;
  float: right;
  font-size: 21px;
  font-weight: normal;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
  opacity: .2;

  &:hover {
    text-decoration: none;
  }
}


.success-banner {
  border-radius: 10px;
  background: aliceblue;

  .success-text,
  .glyphicon {
    color: $brand-success;
  }
}

.error-banner {
  border-radius: 10px;
  background: #ffe7f2;

  .error-text,
  .glyphicon {
    color: $brand-danger;
  }
}

.top-regular-menu.list-inline {
  line-height: 35px;
}

.rotate {
  transform:rotate(180deg);
}

.pointer {
  cursor:pointer;
}

.select2-container--disabled .select2-selection--multiple .select2-search--inline .select2-search__field {
  width: 200px!important;
}

.fa.offset-0
{
  margin:0;
}

.margin-up-5
{
  margin-top: 5px;
}

.panel-sync-info {
  margin: 10px 0 7px 0;
  display: block;

  .sync-item {
    display: block;

    span {
      font-weight: 500;
    }
  }
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.vertical-center {
  vertical-align: middle;
  float: none;
}

.no-padding-right {
  padding-right: 12px!important;
}

.panel-filters {
    color: $gray-dark;
    margin: 5px 0 0;
    width: 100%;

    @include phone {
      font-size: $font-size-small;
    }
}

.btn-purple {
  background: #8870d5;
  color: #f3f0fa;

  &:hover {
    background: #7f66d2;
    color: #f3f0fa;
  }
}

.text-purple {
  color: #8870d5;
}
