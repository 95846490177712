$backgroundColor: #f0f3f4;
$hoverBackgroundColor: #E0E5E6;
$fontColor: #333333;
$borderRadius: 5px;
$statusOperational: #7ED321;
$statusOutage: #ff6f6f;
$separatorColor: #FFFFFF;
$uptimeBackground: rgba(126, 211, 33, .2);
$downtimeBackground: rgba(255, 111, 111, .2);

@mixin radiusOutline($radius, $offset, $color) {
	&:after {
		content: '';
		position: absolute;
		right: 0;
		left: -$offset;
		top: -$offset;
		bottom: 0;
		-webkit-border-radius: $radius;
		-moz-border-radius: $radius;
		border-radius: $radius;
		border: 1px solid $color;
		width: $radius + $offset*2;
		height: $radius + $offset*2;
	}
}

.aggregated-status-page {
	&__container {
		margin: 40px 100px;
		background: $backgroundColor;
		color: $fontColor;
		-webkit-border-radius: $borderRadius;
		-moz-border-radius: $borderRadius;
		border-radius: $borderRadius;
	}

	.integrations-status {
		padding: 20px;

		&:not(:last-child) {
			border-bottom: 1px solid $separatorColor;
		}

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;

			> h3 {
				margin: 0;
			}
		}

		&__integrations-rows {
			display: none;

			&.expanded {
				margin-top: 20px;
				display: block;
			}
		}
	}

	.integration-row {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: 10px;
		padding: 10px;
		-webkit-border-radius: $borderRadius;
		-moz-border-radius: $borderRadius;
		border-radius: $borderRadius;

		&:hover {
			background-color: $hoverBackgroundColor;
		}

		&__item {
			padding: 3px 0;
			cursor: pointer;

			&--name {
				font-weight: 500;
				flex: 1;
			}

			&--status {
				margin-right: 10px;
			}

			&--response-time {
			}

			&--event-history {
				display: none;
				flex-basis: 100%;
				cursor: inherit;
			}
		}
	}

	.integration-event {
		display: flex;
		align-items: center;
		padding: 2px 5px;
		margin: 2px 0;
		-webkit-border-radius: $borderRadius;
		-moz-border-radius: $borderRadius;
		border-radius: $borderRadius;

		&--uptime {
			background-color: $uptimeBackground;
		}

		&--downtime {
			background-color: $downtimeBackground;
		}

		&__item {
			&--type {
				flex: 1;
			}

			&--timestamp {
				margin: 0 20px;
			}

			&--lasting-time {
				width: 150px;
				text-align: right;
				font-size: 80%;
			}
		}
	}

	.status-box {
		&--operational {
			color: $statusOperational;
		}

		&--outage {
			color: $statusOutage;
		}
	}

	.status-circle {
		display: block;
		width: 10px;
		height: 10px;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;
		position: relative;

		&--operational {
			background-color: $statusOperational;
			@include radiusOutline(10px, 2px, $statusOperational);

		}

		&--outage {
			background-color: $statusOutage;
			@include radiusOutline(10px, 2px, $statusOutage);
		}
	}
}