.mapping-list {
	position: relative;

	> h2 {
		margin: 10px;
		display: flex;
		justify-content: center;
	}

	.top-buttons {
		margin: 10px;
		position: absolute;
		right: 0;
		top: 60px;
	}

	.mappings-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;

		.mapping {
			flex-basis: calc(50% - 30px);
			margin: 15px;
			padding: 10px;
			border: 1px solid #e3e3e3;
			border-radius: 5px;
			box-shadow: 0 2px 3px 1px #e3e3e3;
			font-size: 15px;
			cursor: pointer;

			a {
				color: inherit;

				&:hover {
					text-decoration: none;
					color: inherit;
				}
			}

			.mapping-name {
				font-size: 16px;
				font-weight: 700;
				border-bottom: 1px solid #e3e3e3;
				margin-bottom: 10px;
			}

			.mapping-details {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 2px 5px;
				border-radius: 5px;

				&:hover {
					background: #e3e3e3;
				}
			}
		}
	}
}
