.switch {
	width: 50px;
	height: 31px;
	position: relative;
	border-radius: 25px;
	cursor: pointer;
	transition: all 200ms;

	&--on {
		background: rgba(39, 174, 96, 1);

		> div {
			left: 23px;
		}

		&:hover {
			background: rgba(39, 174, 96, 0.5);
		}
	}

	&--off {
		background: rgba(189, 189, 189, 1);

		> div {
			left: 4px;
		}

		&:hover {
			background: rgba(39, 174, 96, 0.5);
		}
	}

	> div {
		width: 23px;
		height: 23px;
		border-radius: 13px;
		background: #ffffff;
		position: absolute;
		top: 3px;
		box-shadow: 0 2px 5px 0 #3e3e3e;
		transition: all 200ms;
	}
}
