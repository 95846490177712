.panel-header {
	margin-top: 72px;
}

.panel-container {
	&.status-page-container {
		padding-top: 10px;
		background-color: #f7f9fa;

		.integration-types {
			padding-top: 20px;
			.integration-type {
				margin-top: 20px;
			}
		}

		.hide {
			display: none;
		}
	}
}

@import "./components/badge";
@import "./components/integrationType";
@import "./components/integrationItem";
@import "./components/integrationDetails";
@import "./components/uptimeChart";
