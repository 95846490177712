.integration-edit {
	&__form-element {
		line-height: 36px;
	}

	.select2-selection--multiple,
	.select2-container--default.select2-container--focus .select2-selection--multiple,
	.select2-dropdown {
		border: 1px solid $gray-lighter;
	}

	.select2-search__field {
		padding-left: 8px !important;
	}

	.select2-selection__arrow b {
		display: none;
	}
}

#integration-edit {
	.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
		display: none;
	}
}

.configurations {
	@media (max-width: 768px) {
		.integration-edit__form-element {
			text-align: left;

			label {
				bottom: -10px;
				color: $gray;
				font-weight: lighter;
				position: relative;
			}
		}
	}
}
