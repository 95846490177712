.integration-details-wrapper {
	display: flex;
	padding: 20px 2% 20px 5%;

	.uptime-chart {
		flex: 0 0 70%;
	}

	.error-rate {
		flex: 0 0 30%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		.title {
			font-size: 24px;
		}

		.value {
			font-size: 64px;
		}
	}
}
