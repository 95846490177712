.panel-importer {
	.import-row {
		&--hidden {
			display: none;
		}
	}

	.panel-container {
		background: inherit;
		padding-left: 0;
		padding-right: 0;

		.nav.nav-tabs {
			border-bottom: 1px solid #d2d2d2;
			display: flex;
			padding-left: 8px;

			li {
				padding: 1rem;
				border-top-right-radius: 3px;
				border-top-left-radius: 3px;
				margin-left: 2px;

				&:hover {
					cursor: pointer;
					background-color: #F1F1F1;
					border-bottom: 1px solid #d2d2d2;
				}

				&.active {
					background-color: #F1F1F1;
					border: 1px solid #d2d2d2;
					border-bottom: none;
				}
			}
		}
	}

	.panel-top {
		padding-top: 0;
		padding-bottom: 0;
	}

	.panel-header-button.panel-input {
		> i {
			position: absolute;
			right: 6px;
			top: 0;
			bottom: 0;
			display: flex;
			align-items: center;
		}

		input {
			border: 1px solid #C1C1C1;
			border-radius: 6px;
			padding: 3px 10px;
		}
	}

	.panel-box {
		&.importer {
			display: none;

			form {
				display: none;
			}

			&.active {
				display: flex;
			}
		}

		&.updater {
			display: none;

			form {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				min-width: 600px;

				p {
					font-size: 15px;
					padding: 0;
					font-weight: 500;
					margin: 15px 0 10px;
				}

				select {
					background-color: #fff;
				}

				textarea {
					border: 1px solid #dcdfe3;
					border-radius: 3px;
					padding: 5px 8px;
					width: 100%;
				}

				.button-wrapper {
					display: flex;
					justify-content: center;
					width: 100%;
				}
			}

			&.active {
				display: flex;
			}
		}

		&.ivr {
			display: none;

			form {
				display: none;
			}

			&.active {
				display: flex;
			}
		}

		button {
			width: 200px;
			height: 50px;
			font-size: 120%;
			text-transform: uppercase;
			margin: 40px 0;

			&:focus {
				color: #FFFFFF;
			}
		}

		span {
			font-weight: 500;
			max-width: 750px;
			text-align: center;
		}
	}

	.panel-table {
		.panel-table-header > .panel-table-header-item,
		.panel-table-body > .panel-table-body-row > div {
			&:nth-child(1) {
				flex-basis: 150px;
				text-align: center;
			}

			&:nth-child(2) {
				flex-basis: 300px;
				padding-left: 20px;
			}

			&:nth-child(3) {
				flex: 1;
			}

			&:nth-child(4) {
				flex-basis: 150px;
				text-align: center;
			}
		}

		margin-bottom: 30px;
	}

	.validation-status {
		font-size: 20px;

		.fa-hourglass-half {
			color: rgba(62, 62, 62, 0.8);
		}

		.fa-times-circle {
			color: rgba(231, 76, 60, 0.9);
		}

		.fa-check-square-o {
			color: rgba(0, 195, 165, 0.8);
		}
	}
}
