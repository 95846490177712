$disabled-text-color: #828282;

.panel-integrations-list, .status-page {
	#refresh-form {
		display: none;
	}

	.panel-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.filters {
		display: flex;
		align-items: center;

		input[type=checkbox] {
			cursor: pointer;
		}
	}

	.filter {
		margin: 0 20px;
	}

	.filter-input {
		padding: 5px 15px;
		width: 250px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&--bold {
			background: #EEF4FD;
			border: 1px solid rgba(61, 131, 223, 0.5);
			border-radius: 2px;
			font-weight: bold;
			color: #2F80ED;
		}
	}

	.filter-select {
		position: relative;
		user-select: none;

		.filter-input {
			cursor: pointer;

			&:after {
				content: '\f078';
				font-family: FontAwesome;
				font-weight: 500;
			}
		}

		&--expanded {
			.filter-input {
				&:after {
					content: '\f077';
					font-family: FontAwesome;
					font-weight: 500;
				}
			}

			.filter-expand {
				// We will trigger this with jquery slide
				//display: block;
			}
		}

		&--with-options {
			.filter-expand {
				padding: 0 0 15px;

				.separator {
					margin-top: 0;
				}
			}

			.select-body {
				padding: 0;
			}
		}

		.checkbox-input {
			> label {
				display: flex;
				align-items: center;
				font-weight: inherit;
				cursor: pointer;
			}

			input {
				margin: 0;
			}

			span {
				margin-left: 5px;
			}

			&:not(:first-child) {
				margin: 3px 0;
			}
		}
	}

	.filter-expand {
		display: none;
		position: absolute;
		top: 35px;
		background: #ffffff;
		width: 100%;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
		border-radius: 4px;
		padding: 15px 0;

		> * {
			padding-left: 10px;
			padding-right: 10px;
		}

		.separator {
			padding: 0;
			width: 100%;
			height: 0;
			border-top: 1px solid rgba(193, 193, 193, 0.5);
			margin: 10px 0;
		}

		.select-buttons {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.filter-select-option {
		padding: 7px 10px;
		cursor: pointer;

		&:hover {
			background: rgba(61, 131, 223, 0.06);
		}

		&--selected {
			background: rgba(61, 131, 223, 0.1);

			&:hover {
				background: rgba(61, 131, 223, 0.1);
			}
		}

		&--before-selected {
			box-shadow: 0 3px 5px 0 rgba(193, 193, 193, 0.5);
		}

		&--after-selected {
			box-shadow: 0 -3px 5px 0 rgba(193, 193, 193, 0.5);
		}

		&.health-option {
			text-align: center;
			text-transform: lowercase;

			&--healthy {
				background: rgba(0, 195, 165, 0.1);

				&:hover {
					background: rgba(0, 195, 165, 0.8);
					transition: all 500ms;
				}

				&.filter-select-option--selected {
					background: rgba(0, 195, 165, 0.8);
				}
			}

			&--unhealthy {
				background: rgba(231, 76, 60, 0.1);

				&:hover {
					background: rgba(231, 76, 60, 0.5);
					transition: all 500ms;
				}

				&.filter-select-option--selected {
					background: rgba(231, 76, 60, 0.5);
				}
			}
		}
	}

	.search {
		display: flex;
		align-items: center;
		justify-content: space-between;

		> b {
			margin-right: 5px;
		}

		.search-input {
			position: relative;

			> i {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 10px;
				display: flex;
				align-items: center;
			}
		}

		input {
			padding: 5px 15px;
		}
	}

	.list-column {
		padding: 0 20px;

		&--health {
			flex-basis: 100px;
			text-align: center;
		}

		&--name {
			flex: 1;
		}

		&--software {
			flex-basis: 400px;
		}

		&--status {
			flex-basis: 160px;
			text-align: right;
		}
	}

	.integration-row {
		&:hover {
			cursor: pointer;
			background: rgba(61, 131, 223, 0.06);

			&:not(.panel-table-body-row--red) {
				box-shadow: 0 0 5px 0 #2F80ED;
			}
		}

		&--hidden {
			display: none;
		}

		.integration-health {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.integration-overview {
			color: #1662C6;

			.integration-name {
				font-size: 20px;
				font-weight: bold;
			}

			.mapped-addresses {
				display: inline-block;
				background: #EEF4FD;
				padding: 5px 10px;
				text-transform: uppercase;
				font-weight: bolder;
			}

			.deleted-label {
				display: none;
			}
		}


		.integration-software {
			font-size: 18px;
			text-transform: uppercase;
			font-weight: bold;
		}

		.integration-status {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.restore-btn {
				display: none;
			}

			> .unlock-btn {
				display: none;
			}
		}

		&--disabled {
			.integration-health {
				* {
					display: none;
				}
			}

			.integration-overview {
				color: #AEAEAE;

				.mapped-addresses {
					background: #F2F2F2;
					color: $disabled-text-color;
				}
			}

			.integration-software {
				color: $disabled-text-color;
			}

			.integration-status {
				color: $disabled-text-color;
			}
		}

		&--deleted {
			.integration-health {
				* {
					display: none;
				}
			}

			.integration-overview {
				color: #AEAEAE;

				.mapped-addresses {
					display: none;
				}

				.deleted-label {
					display: inline-block;
					background: transparent;
					color: $brand-danger;
					border: 1px solid $brand-danger;
					padding: 5px 10px;
					text-transform: uppercase;
					font-weight: bolder;
				}
			}

			.integration-software {
				color: $disabled-text-color;
			}

			.integration-status {
				.switch {
					display: none;
				}
			}

			&:hover {
				.integration-status {
					.restore-btn {
						display: inline-flex;
					}
				}
			}
		}

		&--locked {
			.health-indicator {
				color: $brand-warning;
				width: auto;
				height: auto;
				border-radius: 0;
				background: none;
				box-shadow: none;
				animation: none;

				i {
					display: block;
					font-size: 30px;
				}
			}

			.integration-status {
				.switch {
					display: none;
				}
			}

			&:hover {
				.integration-status {
					.unlock-btn {
						display: inline-flex;
					}
				}
			}
		}
	}

	.health-indicator {
		width: 13px;
		height: 13px;
		border-radius: 7px;

		&--healthy {
			background: $brand-success;
			box-shadow: 0 0 15px 1px $brand-success;
		}

		&--unhealthy {
			background: $brand-danger;
			box-shadow: 0 0 15px 1px $brand-danger;
			animation: pulse-red 2s infinite;
		}

		i {
			display: none;
		}
	}

	@keyframes pulse-red {
		0% {
			box-shadow: 0 0 15px 1px $brand-danger;
		}
		40% {
			box-shadow: 0 0 15px 3px $brand-danger;
		}
		100% {
			box-shadow: 0 0 15px 1px $brand-danger;
		}
	}

	@keyframes pulse-green {
		0% {
			box-shadow: 0 0 15px 1px $brand-success;
		}
		50% {
			box-shadow: 0 0 15px 3px $brand-success;
		}
		100% {
			box-shadow: 0 0 15px 1px $brand-success;
		}
	}

	.reset-btn {
		font-size: 15px;
		color: #627282;
		cursor: pointer;
		text-align: center;
		width: 100%;
	}
}
