$container-margin: 20px;
$container-side-padding: 40px;
$container-top-padding: 20px;
$container-background: #F1F1F1;
$title-size: 36px;
$table-item-border: 1px solid #C1C1C1;
$table-item-border-radius: 6px;

@mixin display-flex($justifyContent: space-between, $alignItems: center) {
	display: flex;
	justify-content: $justifyContent;
	align-items: $alignItems;
}

@mixin table-item-border($border: $table-item-border, $radius: $table-item-border-radius) {
	border: $table-item-border;
	border-radius: $table-item-border-radius;
}

.btn-tuotempo {
	color: black;
	background-color: white;
	transition: all 0.2s linear;

	&:hover {
		color: white;
		background-color: #59cc79;
	}
}

.btn-show-anti-flood-history {
	background-color: #ffc600;
	border-color: #ffc600;

	&:hover {
		border-color: transparent;
		background-color: #eeb900;
	}

	&.disabled {
		background-color: #dcdfe3;
		border-color: #dcdfe3;
	}
}

.panel-top {
	background: $container-background;
	padding: 15px 30px;
}

.panel-header {
	@include display-flex;
	margin: $container-margin;
}

.panel-title {
	margin: 10px 0 20px;
	font-size: $title-size;
	font-weight: bold;
}

.panel-header-buttons {
	@include display-flex;
}

.panel-header-button {
	margin: 0 5px;
}

.panel-input {
	position: relative;
}

.panel-container {
	background: $container-background;
	margin: $container-margin;
	padding: $container-top-padding $container-side-padding;
}

.panel-table {
	.panel-table-header {
		@include display-flex;
		@include table-item-border;
		background: #ffffff;
		border: $table-item-border;
		padding: 10px 20px;
		margin-bottom: 20px;
		font-size: 18px;
	}

	.panel-table-header-item {
		font-weight: bold;
		text-align: left;
	}

	.panel-table-body-row {
		@include display-flex;
		background: #ffffff;
		padding: 20px;
		margin: 10px 0;
		box-shadow: 0 0 4px 0 rgba(62, 62, 62, 0.3);
		border-radius: $table-item-border-radius;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&--red {
			box-shadow: 0 0 5px 0 $brand-danger;
		}
	}

	&--condensed {
		.panel-table-header {
			background: #F1F1F1;
			border-radius: $table-item-border-radius $table-item-border-radius 0 0;
			margin: 0;
		}

		.panel-table-header-item {
			text-transform: uppercase;
			font-size: 14px;
		}

		.panel-table-body-row {
			margin: 0;
			box-shadow: none;
			border-radius: 0;
			border-left: $table-item-border;
			border-right: $table-item-border;
			position: relative;

			.doctor,
			.subscriber-id {
				font-weight: 500;
			}

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				width: calc(100% - 40px);
				height: 1px;
				border-bottom: $table-item-border;
			}
		}

		.row-see-all {
			background: #ffffff;
			text-align: center;
			padding: 15px 0 25px;
			border-left: $table-item-border;
			border-right: $table-item-border;
			border-bottom: $table-item-border;
			color: #4564B5;
			cursor: pointer;
			position: relative;

			&:after {
				content: '\f078';
				font-family: FontAwesome;
				font-weight: 500;
				bottom: 5px;
				left: 0;
				right: 0;
				margin: auto;
				position: absolute;
			}
		}

		.row-see-all:not(.hidden) ~ div {
			display: none;
		}

		.row-see-less {
			background: #ffffff;
			text-align: center;
			padding: 25px 0 15px;
			border-left: $table-item-border;
			border-right: $table-item-border;
			border-bottom: $table-item-border;
			color: #4564B5;
			cursor: pointer;
			position: relative;

			&:after {
				content: '\f077';
				font-family: FontAwesome;
				font-weight: 500;
				top: 5px;
				left: 0;
				right: 0;
				margin: auto;
				position: absolute;
			}
		}
	}
}

// Top header
$burger-size: 55px;
$burger-padding: 10px;
$burger-color: #ffffff;
$top-header-height: 60px;
.panel-top-header {
	height: $top-header-height;
	background: $brand-success;
	padding: 0 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	z-index: 1502;

	.logo {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex: 1;

		span {
			color: #fff;
			font-weight: 100;
			opacity: .8;
			position: relative;
			font-size: 24px;
			font-family: 'Noto Sans', sans-serif;
			display: inline-block;
			vertical-align: middle;
			padding-left: 5px;
		}

		img {
			height: 34px;
		}
	}

	$item-height: 50px;

	.layout-settings {
		margin-right: 15px;

		i {
			color: #ffffff;
			font-size: 25px;
			cursor: pointer;
		}

		.settings-expand {
			display: none;
			position: absolute;
			top: 60px;
			width: 250px;
			right: 0;
			background: #ffffff;
			box-shadow: -2px 0 5px 0px rgba(0, 0, 0, 0.175), 0px 2px 5px 0px rgba(0, 0, 0, 0.175);
			border-radius: 0 0 0 5px;

			.settings-option {
				margin: 10px 20px;
			}

			input {
				width: 150px;
			}
		}
	}

	.menu {
		position: absolute;
		top: $top-header-height;
		right: 0;
		width: 250px;
		display: flex;
		flex-direction: column;
		height: 0;
		transition: all 500ms;
		cursor: pointer;
		overflow: hidden;

		&--expanded {
			height: 8 * $item-height + 7 * 1px + 5px; // items + borders + shadow-box
		}

		.menu-item {
			line-height: $item-height - 20px;
			text-transform: uppercase;
			color: #828282;
			border-left: 1px solid #E0E0E0;
			font-weight: bold;
			z-index: 1503;
			box-shadow: -2px 0 5px 0 rgba(0, 0, 0, .175);
			margin-left: 5px;

			&:last-child {
				box-shadow: -2px 0 5px 0px rgba(0, 0, 0, .175), 0px 2px 5px 0px rgba(0, 0, 0, .175);
				margin-bottom: 5px;
			}

			&:not(:last-child) {
				border-bottom: 1px solid #E0E0E0;
			}

			a {
				color: inherit;
				padding: 10px 20px;
				width: 100%;
				height: 100%;
				display: block;
				background: #ffffff;
				position: relative;

				&:hover {
					text-decoration: none;
					background: rgba(61, 131, 223, .19);
				}

				&:after {
					content: "";
					background: #fff;
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					z-index: -1;
				}

				i {
					margin-right: 5px;
				}
			}
		}
	}

	.burger {
		position: relative;
		width: $burger-size;
		height: $burger-size;
		padding: $burger-padding + 5px $burger-padding;
		cursor: pointer;
		transition: all 500ms ease-in;
		border-radius: $burger-size;

		&:hover {
			background: rgba(2, 221, 187, 1);
			transform: rotate(45deg);
		}

		&--active {
			background: rgba(2, 221, 187, 1);
			transform: rotate(90deg);

			&:hover {
				background: rgba(2, 221, 187, 1);
				transform: rotate(90deg);
			}
		}

		.burger-part {
			position: absolute;
			left: $burger-padding;
			right: $burger-padding;
			max-height: 0;
			border: 2px solid $burger-color;

			&--top {
				top: $burger-padding + 5px;
			}

			&--mid {
				top: $item-height / 2;
			}

			&--bot {
				bottom: $burger-padding + 5px;
			}
		}
	}
}

.panel-btn {
	@extend .btn;

	&--dark-blue {
		@extend .btn-info;
	}

	&--light-blue {
		background: #EEF4FD;
		border: 1px solid rgba(61, 131, 223, 0.5);
		color: #1662C6;
		font-weight: bold;

		&:hover {
			background: rgba(61, 131, 223, 0.1);
			color: #1662C6;
		}
	}

	&--darker-blue {
		background: #4665B6;
		color: white;
		font-weight: bold;
		transition: background 200ms ease-in-out;

		&:hover {
			color: white;
			background: #1f328f;
		}
	}
}

.panel-box {
	@include display-flex(space-between, center);
	flex-direction: column;

	background: #F1F1F1;
	padding: 30px;
}

.panel-modal {
	&.in {
		display: flex !important;
		justify-content: center;
		align-items: center;
	}
}

.modal-backdrop.in {
	display: none;
}
