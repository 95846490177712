.mapping-panel {
	min-height: 100vh;
	position: relative;

	.loader-overlay {
		display: none;
	}

	&.loading {
		> *:not(.top-header):not(.loader-overlay) {
			filter: blur(8px);
		}

		.loader-overlay {
			position: fixed;
			left: 0;
			top: 0;
			width: 100vw;
			height: 100vh;
			background: rgba(62, 62, 62, 0.5);
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			z-index: 1;

			.loader {
				width: 80px;
				height: 80px;
			}
		}
	}

	> h2 {
		margin: 20px;
	}

	.form-box {
		background: #ffffff;
		padding: 20px 20px 35px;
		margin: 10px 20px;
		border-radius: 5px;
		box-shadow: 1px 3px 1px 0 #e3e3e3;
		position: relative;

		.form-header {
			margin-bottom: 0;
		}

		.form-desc {
			margin-bottom: 10px;
			font-size: 80%;
			color: #6c757d;
			max-width: calc(100% - 130px);
		}

		.form-input {
			margin: 10px 0;
		}

		.form-edit-button {
			display: none;
		}

		.automatch-btn {
			position: absolute;
			right: 20px;
			top: 20px;
		}

		&--disabled {
			> form {
				display: flex;
				flex-flow: column;
			}

			.form-input-group {
				display: flex;
				flex-flow: column;
			}

			.form-input {
				&:not(.form-input--select) {
					background: #D6E4FA;
					color: #2F71D3;
					display: inline-flex;
					align-items: center;
					padding: 0 20px;
					margin: 5px 0;
					font-size: 15px;

					> span {
						color: inherit;
						font-size: inherit;
						padding-right: 5px;
						min-width: 120px;
					}

					> input {
						border: none;
						display: inline;
						background: inherit;
						color: inherit;
						width: auto;
						font-weight: 700;
						font-size: inherit;
					}
				}

				&--select {
					.select2-selection {
						background: #ffffff;
						border: none;

						&__choice {
							border: none;
							border-radius: 0;
							padding: 10px;
						}
					}
				}
			}

			.form-edit-button {
				position: absolute;
				right: 20px;
				top: 20px;
				display: block;
			}
		}
	}

	.doctor-mappings {
		display: flex;
		flex-flow: column;

		.doctor-mappings-header {
			display: flex;
			align-items: center;
			background: #D6E4FA;
			padding: 10px 0;
			text-align: center;
			text-transform: uppercase;
			font-size: 16px;
			font-weight: 700;

			.doctor-header {
				flex-basis: 60%;

				&:first-child {
					flex-basis: 40%;
				}
			}

			.integration-indicator-header {
				position: absolute;
				right: 10%
			}
		}

		.doctor-mapping-row {
			display: flex;
			align-items: center;

			.doctor-mapping-field {
				flex-basis: 60%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				font-weight: 500;
				margin: 15px 0;
				position: relative;
				height: 36px;

				&--marketplace-doctor {
					&.auto-matched {
						&:before {
							content: "auto-matched";
							position: absolute;
							height: 100%;
							top: 0;
							left: 0;
							display: flex;
							align-items: center;
							font-weight: 400;
							color: #28a745;
						}
					}
				}

				select {
					max-width: 250px;
					margin: auto;
				}

				.doctor-mapping-confirm,
				.doctor-mapping-unmap {
					position: absolute;
					height: 100%;
					right: 0;
					top: 0;
				}

				&:first-child {
					flex-basis: 40%;
				}
			}

			.integration-indicator {
				font-size: 2em;
				display: inline-block;
				width: 0.1em;
				box-sizing: content-box;
				height: 0.1em;
				border-radius: 0.35em;
				position: absolute;
				right: 10%;
				margin-right: 45px;
			}

			.integration-indicator-not-integrated {
				@extend .integration-indicator;
				border: 0.2em solid red;
				box-shadow: 0 0 0.2em red;
			}

			.integration-indicator-integrated {
				@extend .integration-indicator;
				border: 0.2em solid lightgreen;
				box-shadow: 0 0 0.2em lightgreen;
			}
		}
	}

	.save-all {
		margin: 20px;
		width: calc(100% - 40px);
		z-index: 1;
		height: 50px;
		box-shadow: 0 2px 5px 0 #016360;

		&.fixed {
			position: fixed;
			bottom: 0;
			left: 0;
		}
	}

	.select2-container--default .select2-selection--multiple .select2-selection__choice {
		background: #D6E4FA;
		color: #2F71D3;
		font-weight: 700;
	}
}
