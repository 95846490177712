.badge-pill {
	border-radius: 22px;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.02);
	padding: 7px 19px 7px 15px;
	font-size: 12px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;

	&.success {
		color: #00C3A5;
		background-color: #E0F7F4;

		.badge-pill__icon-status {
			background-color: #00C3A5;
			> i {
				color: #E0F7F4;
				transform: translateY(-1px);
			}
		}

		&.active {
			box-shadow: 0 0 10px 0 #00c3a5;
		}
	}

	&.danger {
		color: #EB5757;
		background-color: #FDF2F1;

		.badge-pill__icon-status {
			background-color: #EB5757;
			> i {
				color: #FDF2F1;
			}
		}

		&.active {
			box-shadow: 0 0 10px 0 #e74c3c;
		}
	}

	&.disabled {
		color: #333333;
		background-color: rgba(51, 51, 51, 0.03);

		.badge-pill__icon-status {
			background-color: #333333;
			> i {
				color: #E0F7F4;
				transform: translateY(-1px);
				font-size: 9px;
			}
		}

		&.active {
			box-shadow: 0 0 10px 0 #333333;
		}
	}

	&__icon-status {
		padding: 0 5px;
		border-radius: 50%;
	}

	&__value {
		margin-left: 7px;
		font-size: 14px;
	}
}
