.integration-type {
	background-color: #F6F6F6;
	border-radius: 4px;
	user-select: none;
	border: 1px solid #F6F6F6;
	transition: all 0.3s linear;
	display: flex;
	flex-direction: column;

	&.active {
		.integration-type {
			&__action {
				i {
					transform: rotate(90deg);
				}
			}

			&__details {
				display: block;
			}
		}

		border: 1px solid #00C3A5;
		box-shadow: 0 0 4px 4px #E0F7F4;
	}

	&__general {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 32px 50px;
	}

	&:hover {
		border: 1px solid #00C3A5;
		box-shadow: 0 0 4px 4px #E0F7F4;
	}

	&__action {
		width: 20px;
		height: 20px;

		> i {
			font-size: 25px;
			color: #888888;
			transition: 0.3s transform linear;
		}
	}

	&__name {
		display: flex;
		align-items: center;
		width: 100%;
		cursor: pointer;

		> p {
			font-weight: bold;
			font-size: 36px;
			margin-bottom: 0;
			margin-left: 20px;
		}
	}

	&__stats {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		.badge-pill {
			margin-top: 8px;
			cursor: pointer;
			width: 100%;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	&__details {
		display: none;
	}
}
