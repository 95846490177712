/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com) extended by DocPlanner (http://frontend.docplanner.io/dpuikit/)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "~@dp-ui-kit/scss/src/bootstrap-variables";
@import "~@dp-ui-kit/scss/src/bootstrap-mixins";
@import "~@dp-ui-kit/scss/src/components-mixins";
@import "~@dp-ui-kit/scss/src/index";
