#integration-page .panel-heading-action {
	display: block;
}

#integration-page .panel-heading-action .panel-title {
	width: 100%;
}

#integration-page .panel-heading-action .panel-action {
	float: right;
}
