.uptime-chart-wrapper {
	display: flex;
	flex-direction: column;

	.title {
		font-size: 24px;
		text-align: left;
	}

	.chart-wrapper {
		.general-rate {
			width: 100%;
			text-align: right;
		}

		.chart {
			display: flex;
			justify-content: flex-start;
			width: 100%;
			margin: 8px 0;

			.single-day {
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-around;
				margin: 0 1px;

				.chart-status {
					width: 100%;
				}

				.success {
					background-color: #0bad64;
				}

				.error {
					background-color: #f5061e;
				}
			}
		}

		.description {
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
	}

	.additional-info {
		margin-top: 10px;
		font-size: 16px;

		p {
			margin: 0;
		}
	}
}
