.filtered-out {
	display: none !important;

	+ hr {
		display: none !important;
	}
}

li.select2-results__option {
	border-bottom: 1px solid #eeeeee;
}

.online-label {
	text-align: center;
	padding: 2px 10px;
	font-weight: 700;
	text-transform: uppercase;
	background: #f3f0fa;
	display: inline;
	font-size: 12px;
}
