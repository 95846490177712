.status-page {
	.panel {
		.panel-heading.integration-working {
			background-color: $brand-success;
			.panel-title {
				color: $white;
				.integration-refresh {
					cursor: pointer;
				}
			}
		}
		.panel-heading.integration-not-working {
			background-color: $brand-danger;
			.panel-title {
				color: $white;
			}
		}
	}
	.integration-status-panel {
		li {
			font-size: 17px;
			font-weight: 600;
			line-height: 30px;
		}
	}

	.integrated-facilities-panel {
		.panel-body {
			.facility {
				margin-right: 5px;
				padding: 10px 10px;
				border: 1px solid #eee;
				font-weight: 700;
			}
		}
	}

	.integrated-doctors-panel {
		.panel-body {
			div {
				margin-bottom: 5px;
				.doctor {
					margin-right: 5px;
					padding: 10px 10px;
					border: 1px solid $brand-success-dark;
					border-radius: 7px;

					li:first-child {
						font-size: 15px;
						font-weight: 600;
					}
				}
			}
		}
	}

	.integration-fails-panel {
		.panel-body {
			.fails {
				li {
					margin-bottom: 10px;
					.time {
						font-weight: 600;
						margin-bottom: 10px;
					}
				}

				li:last-child{
						margin-bottom: 0;
				}
			}
		}
	}
}