.integrations-list {


  a:hover, a:focus {
    text-decoration: none;
  }

  .list-element {
    border: 1px solid #eef2f2;
    border-radius: 6px;
    padding: 10px 0;

    .label {
      line-height: 2;
    }

    .inline-block{
      display: inline-block;
    }
  }

  &__section-title,
  .select2-selection--multiple {
    border-bottom: 1px solid $gray-lighter;
  }

  .select2-selection--multiple,
  .select2-container--default.select2-container--focus .select2-selection--multiple,
  .select2-dropdown {
    border: 1px solid $gray-lighter;
  }

  .select2-search__field {
    padding-left: 8px !important;
  }

  .select2-selection__arrow b {
    display: none;
  }

  .no-matches {
    border: 1px solid #dc3545;
  }
}
